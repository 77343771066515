//
// Global Framework
//



// Theme Base Components
@import "components/base/include";

// Core Components
@import "components/foundation/page-loader/page-loader";
@import "components/foundation/normalize/normalize";
@import "components/foundation/animations/animations";
@import "components/foundation/helpers/helpers";
@import "components/foundation/grid/mixins";
@import "components/foundation/grid/grid";
@import "components/foundation/container/container";
@import "components/foundation/datatable/datatable";
@import "components/foundation/scrolltop/scrolltop";
@import "components/foundation/portlet/portlet";
@import "components/foundation/form/form/form";
@import "components/foundation/form/input-icon/config";
@import "components/foundation/form/input-icon/input-icon";
@import "components/foundation/form/checkbox/config";
@import "components/foundation/form/checkbox/checkbox";
@import "components/foundation/form/radio/config";
@import "components/foundation/form/radio/radio";
@import "components/foundation/form/switch/switch";
@import "components/foundation/form/option/option";
@import "components/foundation/form/avatar/avatar";
@import "components/foundation/indicator/spinner/config";
@import "components/foundation/indicator/spinner/mixins";
@import "components/foundation/indicator/spinner/spinner";
@import "components/foundation/indicator/loader/config";
@import "components/foundation/indicator/loader/loader";
@import "components/foundation/indicator/pulse/pulse";
@import "components/foundation/dialog/dialog";

// Core Vendors
@import "components/vendors/perfect-scrollbar/perfect-scrollbar";
@import "components/vendors/blockui/blockui";
@import "components/vendors/fullcalendar/fullcalendar";
@import "components/vendors/bootstrap-datepicker/bootstrap-datepicker";
@import "components/vendors/bootstrap-notify/bootstrap-notify";
@import "components/vendors/bootstrap-switch/bootstrap-switch";
@import "components/vendors/bootstrap-datetimepicker/bootstrap-datetimepicker";
@import "components/vendors/bootstrap-timepicker/bootstrap-timepicker";
@import "components/vendors/bootstrap-daterangepicker/bootstrap-daterangepicker";
@import "components/vendors/bootstrap-touchspin/bootstrap-touchspin";
@import "components/vendors/bootstrap-maxlength/bootstrap-maxlength";
@import "components/vendors/bootstrap-select/bootstrap-select";
@import "components/vendors/bootstrap-session-timeout/bootstrap-session-timeout";
@import "components/vendors/jqvmap/jqvmap";
@import "components/vendors/ion-range-slider/ion-range-slider";
@import "components/vendors/typeahead/typeahead";
@import "components/vendors/nouislider/nouislider";
@import "components/vendors/summernote/summernote";
@import "components/vendors/bootstrap-markdown/bootstrap-markdown";
@import "components/vendors/dropzone/dropzone";
@import "components/vendors/recaptcha/recaptcha";
@import "components/vendors/toastr/toastr";
@import "components/vendors/gmaps/gmaps";
@import "components/vendors/fontawesome5/fontawesome5";
@import "components/vendors/jstree/jstree";
@import "components/vendors/select2/select2";
@import "components/vendors/sweetalert2/sweetalert2";
@import "components/vendors/datatables/datatables";
@import "components/vendors/jquery-repeater/jquery-repeater";

// CUSTOM CSS
/*@import "./../../../../../sass/_variables";
@import "./../../../../../sass/general";
@import "./../../../../../sass/general";
*/