//
// Demo Config
//




// State colors
$kt-state-colors: (
    // Metronic states
    brand: (
        base: #22b9ff,
        inverse: #ffffff
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c 
    ),
    dark: (
        base: #282a3c,
        inverse: #ffffff 
    ),

    // Bootstrap states
    primary: (
        base: #2196F3,
        inverse: #ffffff
    ),
    success: (
        base: #00C851,
        inverse: #ffffff
    ),
    info: (
        base: #2786fb,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #ff4444,
        inverse: #ffffff
    )    
);

// Layout
$kt-aside-default-width: 250px;
$kt-aside-minimize-width: 100px;
$kt-aside-offcanvas-width: 255px;

$kt-page-padding: (
	desktop: 25px,
	mobile: 15px
);

// Brand Color
$kt-brand-inverse-color: #ffffff;
$kt-brand-hover-color: #f5f6fc;

// Secondary Brand Color
$kt-secondary-brand-color: #fbce44;

// Page bg color
$kt-page-bg-color: #f9f9fc;  // page bg color

// Aside bg color
$kt-aside-bg-color: #fff;