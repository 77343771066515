//
// Modal
//




// Base
@mixin kt-modal-base() {
    // Base
    .modal {    
        .modal-content {
            @include kt-rounded {
                border-radius: $kt-border-radius;
            }

            .modal-header {                
                .modal-title {
                    font-weight: 500;
                    font-size: 1.3rem;
                    color: kt-base-color(label, 4);

                    small {
                        font-weight: 400;
                        font-size: 0.9rem;
                        color: kt-base-color(label, 2);
                    }
                }

                .close  {
                    color: kt-base-color(label, 2);
                    @include kt-close-icon();
                    @include kt-hover-transition();
                    
                    &:before {
                        font-size: 1.3rem;
                    }
                    
                    outline:none !important;
                    span {
                        display: none;    
                    }

                    &:hover {
                        color: kt-brand-color();
                    }
                }
            }
        }    
    }

    .modal-open {
        overflow: auto !important;
        padding: 0 !important;
    }

    .modal-body {
        &.modal-body-fit {
            padding: 0;
        }

        &.modal-body-x-fit {
            padding-left: 0;
            padding-right: 0;
        }

        &.modal-body-y-fit {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @include kt-media-above(xl) {
        .modal-dialog.modal-xl { 
            max-width: kt-media-breakpoint(xl) - 100px;
        } 
    }
}

// Skin
@mixin kt-modal-skin() {
    .modal {    
        .modal-content {
            //box-shadow: ;

            .modal-header {
                .modal-title {
                    color: kt-base-color(label, 4);
                }

                .close  {
                    span {
                        color: kt-base-color(label, 2);
                    }
                }
            }
        }   
    }
}

// Build
// Base
@include kt-modal-base();

// Skin
@include kt-modal-skin();