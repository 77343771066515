//
// Demo Panel
//



$kt-demo-panel: (
	self: (
		bg-color: #fff,
		shadow: 0px 0px 28px 0px rgba(82,63,105,0.13),

		offcanvas: (
			layout: (
				self: (
					zindex: 1001,
					direction: right,
					transition:	all .3s ease,
					width: 350px
				),

				close: (
					size: 25px,
					font-size: 1.4rem
				)
			),

			skin: (
				overlay: (
					bg-color: rgba(#000000, 0.05),
				)
			)
		)
	)
)