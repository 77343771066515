//
// Table
//



.table {
	thead {
		th, td {
			font-weight: 500;
			border-bottom-width: 1px;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	tr.table-active {
		td, th {
			color: kt-state-color(primary, inverse);
		}
	}

	&.table-head-noborder {
		thead {
			th, td {
				border-top: 0;
			}
		}
	}

	&.table-head-solid {
		thead {
			th, td {
				background-color: kt-base-color(grey, 1);
			}
		}
	}
}

.table:not(.table-bordered) {
	thead {
		th, td {
			border-top: 0;
		}
	}
}