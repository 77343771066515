//
// Widget24
//



 
.kt-widget24 {
    padding: kt-get($kt-portlet, space, desktop);
    flex: 1;       

    .kt-widget24__details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .kt-widget24__info {
            .kt-widget24__title {
                font-size: 1.1rem;
                font-weight: 600; 
                color: kt-base-color(label, 3);
            } 

            .kt-widget24__desc {
                color: kt-base-color(label, 2);
            }             
        } 

        .kt-widget24__stats {
            font-size: 1.75rem;
            font-weight: 600;
            padding-left: 0.5rem;
        } 
    }

    .progress {
        height: 0.5rem;  
        margin: 2.1rem 0 0.6rem 0;
    }

    .kt-widget24__action {
        display: flex;
        justify-content: space-between;

        .kt-widget24__change {
            color: kt-base-color(label, 2);
        }

        .kt-widget24__number {
            color: kt-base-color(label, 2);
        }            
    }          
} 

 
@include kt-tablet-and-mobile {
    .kt-widget24 {
        padding: kt-get($kt-portlet, space, mobile);
    }
}